import { useWeb3React } from "@web3-react/core";
import { Injected } from "src/utils/connectors";
import cn from "classnames/bind";
import { formatAddress } from "src/utils/utils";
import styles from "./index.module.scss";
// import ModalAvatar from "./Modal";

const cx = cn.bind(styles);

const ConnectButton = () => {
  const { activate, deactivate, account } = useWeb3React();
  const handleClick = () => {
    if (!account) return activate(Injected);
    // return deactivate();
  };

  return (
    <div onClick={handleClick} className={cx("container")}>
      {account ? formatAddress(account) : "Connect Wallet"}
    </div>
  );
};
export default ConnectButton;
