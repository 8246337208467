import { ReactComponent as LawIcon } from "src/assets/icons/law.svg";
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const UPVOTE_SCORE = 10 * 10 ** 5;
export const navs = [
  {
    icon: <LawIcon />,
    label: "Dashboard",
    route: "/",
  },
  {
    icon: <LawIcon />,
    label: "New Proposal",
    route: "/create",
  },
  {
    icon: <LawIcon />,
    label: "My Activities",
    route: "/user-history",
  },
  {
    icon: <LawIcon />,
    label: "History",
    route: "/history",
  },
  {
    icon: <LawIcon />,
    label: "About",
    route: "/about",
  },
  {
    icon: <LawIcon />,
    label: "Log",
    route: "/log",
  },
];

export const TAGS = [
  { value: "kawaii-islands", label: "Kawaii-islands", color: "#D18C00", background: "#FFF8D5", border: "#F2C66C", valueFilter: "islands" },
  { value: "ecosystem", label: "Ecosystem", color: "#8036DD", background: "#EBDCFF", border: "#B381F3", valueFilter: "ecosystem" },
  { value: "trading", label: "Trading", color: "#D83E63", background: " #FFDCE4", border: "#E3839A", valueFilter: "trading" },
  { value: "marketing", label: "Marketing", color: "#2E75DF", background: " #DCEAFF", border: "#71AAFE", valueFilter: "marketing" },
];

export const ROLES = {
  "0x48D1326C8D585aD70d4DeAcD9eA0297e14B95a83": "Chung Dao - Co-founder",
  "0x501C61855159922D650C4B5285d188c0cC15C7da": "Minh Do - Co-founder and PO",
  "0x2CfCF0d3cF785779d3D63eA50ECA3B7d136B79C4": "Reishi - Lead Game Designer",
  "0x9f3e084caD14040Cd1c8d3f0A718895861C41B2f": "Ha Khong - Business Development",
  "0x7A64b92CB9cb6A89bA588c1f2FaCEebE09b66B19": "Mei Mei - Mods",
  "0x37A9ABcc9F237e8FB60f923c2A4368A95321A49D": "Ricky - Mods",
  "0xb7F0Caa4b001F79e8CAbd592d5b6061b08cCFFE5": "Bin - Mods",
  "0x37A2c4c34d71e9D800F35f1D5cDBD3383c20bb1A": "Fantom - Game Designer",
  "0xD7f4039D1C2FC708BB3e082445b7E150EB9f44F7": "Duong Nguyen - Lead Web3 dev",
  "0x512753d45eB3FE58D89Dd382C6E7EB0c433DD278": "Minh Huynh - Lead Game dev",
  "0x66B139A215aEf4B5A7c6efd67527dEefbcD7C5c7": "Hal - Mods",
};

export const votingSystems = ["single-choice", "approval", "quadratic", "weighted"];

export const routeShowSidebar = ["/", "/about", "/history", "/user-history", "/log"];

export const CORE_MEMBERS = [
  "0x2b27332cDCDB6776092fBADf17Ef48741aFFf3CE",
  "0x2db78ecD7f3dFea3146dD8bf0070889a9cBe9B0b",
  "0x9181101D9823EaC941Ddf0a840D8687fB41006a0",
  "0x975C923326822802e56c7DE4893B924F45d3aDF0",
];
