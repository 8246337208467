import cn from "classnames/bind";
import styles from "./index.module.scss";

const cx = cn.bind(styles);

const MainLayout = ({ children, style }) => {
  return <div className={cx("main-container")} style={style}>
    <div className={cx("content")}>{children}</div>
  </div>;
};
export default MainLayout;
