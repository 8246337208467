import web3 from "web3";
export const formatAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 4)}...${address.slice(-4, address.length)}`;
};
export const compactNumberFormatter = new Intl.NumberFormat(
  "en", // currentLocale.value,
  {
    notation: "compact",
    compactDisplay: "short",
  } || { notation: "standard" }
);
export const renderState = (state) => {
  switch (state) {
    case "active":
      return "On Going";
    case "closed":
      return "Closed";
    case "pending":
      return "Pending";
    default:
      return "";
  }
};
const chainId = 1; // Polygon Mainnet

export const switchNetwork = async () => {
  if (window.ethereum.networkVersion !== chainId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.toHex(chainId) }],
      });
    } catch (err) {
      // // This error code indicates that the chain has not been added to MetaMask
      // if (err.code === 4902) {
      //   await window.ethereum.request({
      //     method: "wallet_addEthereumChain",
      //     params: [
      //       {
      //         chainName: "Ether",
      //         chainId: web3.utils.toHex(chainId),
      //         nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
      //         rpcUrls: ["https://polygon-rpc.com/"],
      //       },
      //     ],
      //   });
      // }
    }
  }
};

export const percentOfTotal = (i, selectedChoices) => {
  let total = 0;
  for (let prop in selectedChoices) {
    total += selectedChoices[prop];
  }
  const percent = Math.round(((selectedChoices[i] * 100) / total) * 10) / 10;
  return isNaN(percent) ? 0 : percent;
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("jwt-token");
  try {
    const decode = parseJwt(token);
    const { exp } = decode;
    if (exp < (new Date().getTime() + 1) / 1000) {
      localStorage.removeItem("jwt-token");
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
  if (value === undefined) {
    if (uri.match(re)) {
      return uri
        .replace(re, "$1$2")
        .replace(/[?&]$/, "")
        .replaceAll(/([?&])&+/g, "$1")
        .replace(/[?&]#/, "#");
    } else {
      return uri;
    }
  } else {
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      var hash = "";
      if (uri.indexOf("#") !== -1) {
        hash = uri.replace(/.*#/, "#");
        uri = uri.replace(/#.*/, "");
      }
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      return uri + separator + key + "=" + value + hash;
    }
  }
}
