import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useMediaQuery } from "@mui/material";
import { routeShowSidebar, CORE_MEMBERS } from "src/utils/consts";

import cn from "classnames/bind";
import logo from "src/assets/logo2.png";
import MenuIcon from "@mui/icons-material/Menu";

import styles from "./index.module.scss";
import ConnectButton from "src/components/common/ConnectButton";
import Drawer from "@mui/material/Drawer";
import { navs } from 'src/utils/consts'



const cx = cn.bind(styles);

const Header = () => {
  const mobile = useMediaQuery("(max-width: 1000px)");
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [currentAccount, setCurrentAccount] = useState("");
  const { pathname } = location;
  const { account } = useWeb3React();

  useEffect(() => {
    setCurrentAccount(account);
  }, [account]);

  const address = pathname.split("/")[2];

  return (
    <>
      <div className={cx("container")}>
        <div className={cx("bg")}>
          <Link to="/">
            {" "}
            <img src={logo} alt="logo" />
          </Link>

          <div className={cx("title")}>Kawaiiverse DAO</div>
          {mobile ? <MenuIcon onClick={() => setShow(!show)} style={{ width: 60 }} /> : <ConnectButton />}
        </div>
        <Drawer
          anchor={"right"}
          open={show}
          onClose={() => setShow(false)}
          sx={{
            "& .MuiPaper-root": {
              width: 160,
              padding: "12px 20px",
              background: "#FDECD9",
            },
          }}
        >
          <ConnectButton />
          <div className={cx("line")}></div>
          <div className={cx("nav")}>
            {navs.map((nav, idx) => {
              let isActive = pathname === nav.route;
              if (nav.route.includes("/user-history")) {
                isActive = isActive && address === currentAccount;
                nav.route = `/user-history/${currentAccount}`;
                if (!account) return "";
              }
              if(nav.route === "/log" && !CORE_MEMBERS.includes(account)){
                return;
              }
              return (
                <Link to={nav.route} key={`nav-${idx}`} className={cx("nav-link", { active: isActive })} onClick={() => setShow(false)}>
                  {nav.label}
                </Link>
              );
            })}
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default Header;
