import cn from "classnames/bind";
import styles from "./index.module.scss";

const cx = cn.bind(styles);
const LoadingScreen = () => {
  return (
    <>
      <div className={cx("loader")}></div>
    </>
  );
};
export default LoadingScreen;
