import { useEffect, useState } from "react";
import cn from "classnames/bind";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import { navs, routeShowSidebar, CORE_MEMBERS } from "src/utils/consts";
import MenuIcon from "@mui/icons-material/Menu";
import { useWeb3React } from "@web3-react/core";
const cx = cn.bind(styles);

const Sidebar = () => {
  const location = useLocation();
  const [currentAccount, setCurrentAccount] = useState("");
  const { pathname } = location;
  const { account } = useWeb3React();

  useEffect(() => {
    setCurrentAccount(account);
  }, [account]);

  const address = pathname.split("/")[2];
  if (
    !routeShowSidebar.includes(pathname) &&
    pathname?.split("/")[1] !== "user-history"
  )
    return;

  return (
    <div className={cx("container")}>
      <h3 className={cx("title")}>
        <MenuIcon />
        COMMUNITY
      </h3>
      <div className={cx("navs")}>
        {navs.map((nav, idx) => {
          let isActive = pathname === nav.route;
          if (nav.route.includes("/user-history")) {
            isActive = isActive && address === currentAccount;
            nav.route = `/user-history/${currentAccount}`;
            if (!account) return "";
          }
          if(nav.route === "/log" && !CORE_MEMBERS.includes(account)){
            return;
          }

          return (
            <Link to={nav.route} key={`sidebar-nav-${idx}`}>
              <nav className={cx({ nav: true, "nav--active": isActive })}>
                {/* {nav.icon} */}
                <span>{nav.label}</span>
              </nav>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default Sidebar;
