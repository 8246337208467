import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from "react-router-dom";
import cn from "classnames/bind";
import { useWeb3React } from "@web3-react/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import MainLayout from "src/components/Layout/MainLayout";
import LoadingScreen from "src/components/common/LoadingScreen";
import { useEagerConnect, useInactiveListener } from "./utils/hooks.js";
import styles from "./App.module.scss";
import "swiper/css/bundle";
import "./App.css"; // Reset css

const cx = cn.bind(styles);

const DashboardPage = React.lazy(() => import("src/page/Dashboard"));
const CreatePage = React.lazy(() => import("src/page/Create"));
const HistoryUserPage = React.lazy(() => import("src/page/HistoryUser"));
const HistoryPage = React.lazy(() => import("src/page/History"));
const ProposalPage = React.lazy(() => import("src/page/Proposal"));
const AboutPage = React.lazy(() => import("src/page/About"));
const LogPage = React.lazy(() => import("src/page/Log"))

async function removeJwt() {
  if (!window.ethereum.on) return;
  window.ethereum.on("accountsChanged", function (accounts) {
    localStorage.removeItem("jwt-token");
  });
}

function App() {
  const context = useWeb3React();
  const { connector, library, chainId, account, activate, deactivate, active, error } = context;

  useEffect(() => {
    removeJwt();
  }, []);
  // handle logic to recognize the connector currently being activated
  const isSmallScreen = useMediaQuery("(max-width:1000px)");

  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);
  return (
    <Router>
      <ToastContainer />
      <Header />
      <Suspense fallback={<LoadingScreen />}>
        <div className={cx("bg")}>
          <div className={cx("app")}>
            {!isSmallScreen && <Sidebar />}
            <Routes>
              <Route path="/create" exact element={<CreatePage />} />
              <Route path="/user-history/:address" exact element={<HistoryUserPage />} />
              <Route path="/history" exact element={<HistoryPage />} />
              <Route path="/proposal/:id" exact element={<ProposalPage />} />
              <Route path="/about" exact element={<AboutPage />} />
              <Route path="/log" exact element={<LogPage />} />
              <Route path="/" element={<DashboardPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
